exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cd-index-js": () => import("./../../../src/pages/CD/index.js" /* webpackChunkName: "component---src-pages-cd-index-js" */),
  "component---src-pages-chaos-engineering-index-js": () => import("./../../../src/pages/chaos-engineering/index.js" /* webpackChunkName: "component---src-pages-chaos-engineering-index-js" */),
  "component---src-pages-ci-index-js": () => import("./../../../src/pages/CI/index.js" /* webpackChunkName: "component---src-pages-ci-index-js" */),
  "component---src-pages-contact-us-thank-you-js": () => import("./../../../src/pages/contact-us-thank-you.js" /* webpackChunkName: "component---src-pages-contact-us-thank-you-js" */),
  "component---src-pages-cost-index-js": () => import("./../../../src/pages/cost/index.js" /* webpackChunkName: "component---src-pages-cost-index-js" */),
  "component---src-pages-feature-flags-index-js": () => import("./../../../src/pages/feature-flags/index.js" /* webpackChunkName: "component---src-pages-feature-flags-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-overview-index-js": () => import("./../../../src/pages/overview/index.js" /* webpackChunkName: "component---src-pages-overview-index-js" */),
  "component---src-pages-security-testing-orchestration-index-js": () => import("./../../../src/pages/security-testing-orchestration/index.js" /* webpackChunkName: "component---src-pages-security-testing-orchestration-index-js" */),
  "component---src-pages-service-reliability-management-index-js": () => import("./../../../src/pages/service-reliability-management/index.js" /* webpackChunkName: "component---src-pages-service-reliability-management-index-js" */),
  "component---src-templates-blog-details-index-js": () => import("./../../../src/templates/blog-details/index.js" /* webpackChunkName: "component---src-templates-blog-details-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-news-details-index-js": () => import("./../../../src/templates/news-details/index.js" /* webpackChunkName: "component---src-templates-news-details-index-js" */),
  "component---src-templates-price-index-jsx": () => import("./../../../src/templates/price/index.jsx" /* webpackChunkName: "component---src-templates-price-index-jsx" */)
}

